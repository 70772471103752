<template>
  <v-main>
    <error-snackbar
      v-if="showErrorSnackbar"
      :snackbarText="snackbarText"
      @hidde="showErrorSnackbar = false"
    />
    <v-container style="padding: 0px">
      <v-form @submit.prevent="login">
        <v-row
          no-gutters
          align="start"
          justify="center"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'padding: 20px'
              : 'padding: 50px 0px'
          "
        >
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'text-align:center'
                : 'padding-right: 104px; padding-top: 12px'
            "
          >
            <img
              v-if="!$vuetify.breakpoint.smAndDown"
              src="@/assets/img/PTransLogoBlue.png"
              width="183px"
              alt="Logo"
            />
            <h3 class="loginTitle" style="margin-top: 72px">
              {{ "login_label" | localize }}
            </h3>
            <p class="loginText" style="margin: 8px 0px 0px 0px">
              {{ "login_subtitle" | localize }}
            </p>
            <v-text-field
              style="margin-top: 40px; border-radius: 10px"
              dense
              outlined
              label="Email"
              :placeholder="'type_here' | localize"
              hide-details
              color="#E2E2E2"
              background-color="#FFF"
              height="56px"
              v-model="user.email"
              :error-messages="emailError"
            >
              <template v-slot:append>
                <v-icon
                  color="error"
                  style="margin-top: 8px"
                  v-if="emailError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-text-field
              style="margin-top: 20px; border-radius: 10px"
              dense
              outlined
              :label="'password_label' | localize"
              :placeholder="'type_here' | localize"
              hide-details
              color="#E2E2E2"
              background-color="#FFF"
              :type="isShowPassword ? 'text' : 'password'"
              height="56px"
              v-model="user.password"
              :error-messages="passwordError"
            >
              <template v-slot:append>
                <v-icon
                  style="margin-top: 8px"
                  color="#4B5262"
                  @click="isShowPassword = !isShowPassword"
                  >{{ isShowPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                >
                <v-icon
                  color="error"
                  style="margin-top: 8px; margin-left: 8px"
                  v-if="passwordError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-row no-gutters align="center">
              <v-checkbox width="16px" height="16px" color="#144FA9" />
              <span
                style="
                  color: #4f545a;
                  font-family: 'MacPaw Fixel Display';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                "
                >{{ "remember_me_label" | localize }}</span
              >
              <v-row no-gutters align="center" justify="end">
                <span
                  style="
                    color: #144fa9;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    cursor: pointer;
                    user-select: none;
                  "
                  @click="$router.push('reset-password')"
                  >{{ "reset_password_label" | localize }}</span
                >
              </v-row>
            </v-row>
            <v-btn
              class="detailBtn"
              width="100%"
              style="margin-top: 30px"
              type="submit"
            >
              {{ "sign_in_btn" | localize }}
            </v-btn>
            <v-row
              no-gutters
              align="center"
              justify="center"
              style="margin-top: 16px"
            >
              <span
                style="
                  color: #1b1b1b;
                  text-align: center;
                  font-family: 'MacPaw Fixel';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                "
                >{{ "dont_have_account" | localize
                }}<span
                  style="
                    color: #144fa9;
                    font-weight: 600;
                    margin-left: 8px;
                    cursor: pointer;
                    user-select: none;
                  "
                >
                  <router-link to="/registration">
                    {{ "sign_up_label" | localize }}</router-link
                  ></span
                ></span
              >
            </v-row>
            <v-row
              v-if="!$vuetify.breakpoint.smAndDown"
              no-gutters
              align="center"
              style="margin-top: 40px"
            >
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: 213px;
                  height: 0.5px;
                "
              />
              <p
                style="
                  margin: 0px;
                  color: #4b5262;
                  font-family: 'MacPaw Fixel';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0px 16px;
                "
              >
                {{ "or_login_with" | localize }}
              </p>
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: 213px;
                  height: 0.5px;
                "
              />
            </v-row>
            <v-row v-else no-gutters align="center" style="margin-top: 40px">
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: calc(50% - 30px);
                  height: 0.5px;
                "
              />
              <div style="width: 60px">
                <p
                  style="
                    margin: 0px;
                    color: #4b5262;
                    font-family: 'MacPaw Fixel';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  "
                >
                  {{ "or_login_with" | localize }}
                </p>
              </div>
              <div
                style="
                  opacity: 0.25;
                  background: #1b1b1b;
                  width: calc(50% - 30px);
                  height: 0.5px;
                "
              />
            </v-row>
            <social-buttons />
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="6" class="py-0">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                v-for="i in 3"
                :key="i"
                :cssMode="true"
                :navigation="true"
                :pagination="true"
                :mousewheel="true"
                :keyboard="true"
                :modules="swiperOptions.modules"
              >
                <img
                  src="@/assets/img/routeImg.png"
                  width="100%"
                  height="100%"
                  class="loginImg"
                  style="user-select: none"
                />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>

<script>
import swiperOptionMixin from "../../../mixins/swiperOptionMixin";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import socialButtons from "../../UI/Buttons/socialButtons.vue";
import { mapActions } from "vuex";
import authService from "./../../../requests/Auth/authService.js";
import ErrorSnackbar from "../../UI/Snackbars/errorSnackbar.vue";
import Vue from 'vue';
export default {
  components: { socialButtons, ErrorSnackbar },
  mixins: [swiperOptionMixin, validationMixin],
  data: () => ({
    user: {
      email: "",
      password: "",
    },
    swiperOptions: {
      loop: true,
      initialSlide: 0,
      autoplay: {
        delay: 1500,
        disableOnInteraction: true,
      },
      speed: 800,
    },
    snackbarText: "",
    isShowPassword: false,
    showErrorSnackbar: false,
  }),
  validations: {
    user: {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("email", this.user.email);
        form.append("password", this.user.password);
        await authService
          .signIn(form)
          .then((res) => {
            if (res.status == "Success") {
              const user = res.data.user_info;
              this.updateInfoLogged({
                first_name: user.first_name,
                last_name: user.last_name,
                role: user.role_name,
                email: user.email,
                user_id: user.id,
                token: res.data.token,
              });
              Vue.prototype.$user_role = user.role_name;
              this.$router.push("/");
            }
          })
          .catch(() => {
            this.snackbarText = "Неправильний логін або пароль";
            this.showErrorSnackbar = true;
            setTimeout(() => {
              this.showErrorSnackbar = false;
            }, 3000);
          });
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      let field = this.$v.user.email;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.email) {
        errors.push("");
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      let field = this.$v.user.password;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
  },
};
</script>

<style scoped>
.loginImg {
  border-radius: 30px;
}
@media only screen and (max-width: 1023px) {
  .loginTitle {
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
@media only screen and (min-width: 1024px) {
  .loginTitle {
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.loginText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailBtn {
  border-radius: 10px;
  background: #144fa9 !important;
  color: #fafafa !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 48px !important;
  padding: 12px 16px;
  text-transform: none;
}
</style>
<style>
.swiper-pagination-bullet-active {
  background: #fed500 !important;
  width: 32px;
  height: 10px;
  border-radius: 5px;
  transition: width 0.5s, border-radius 0.3s;
}
.swiper-pagination-bullet {
  background-color: #fafafa;
  opacity: 1;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>